export { PageShell };

import React from "react";
import type { PageContext } from "vike/types";
import { PageContextProvider } from "./usePageContext";
import Nav from "@/components/layout/nav";
import Footer from "@/components/layout/footer";

function PageShell({
  children,
  pageContext,
}: {
  children: React.ReactNode;
  pageContext: PageContext;
}) {
  return (
    <React.StrictMode>
      <PageContextProvider pageContext={pageContext}>
        <main className="flex flex-col overflow-x-hidden   min-h-screen md:px-[90px] py-8 mx-auto max-w-[1560px] relative">
          <Nav />
          {children}
        </main>
        <Footer />
      </PageContextProvider>
    </React.StrictMode>
  );
}
