import { Button } from "../ui/button";
import link1 from "@/assets/socials/Link-1.svg";
import link2 from "@/assets/socials/Link-2.svg";
import link3 from "@/assets/socials/Link-3.svg";
import link from "@/assets/socials/Link.svg";

export default function Footer() {
  return (
    <footer className="text-white bg-primary">
      <div className="flex flex-col items-center max-w-[1560px] mx-auto py-10 px-2">
        <ul className="flex justify-center items-center w-full gap-4">
          <li>
            {/* twitter */}
            <a href="https://twitter.com/eloop_network" target="_blank">
              <img
                className="w-9 cursor-pointer"
                src={link}
                alt="link3"
                width={24}
                height={24}
              />
            </a>
          </li>
          <li>
            {/* linkedin */}
            {/* img from assets/social/link-3.svg */}
            <a
              href="https://www.linkedin.com/company/eloopcarsharing"
              target="_blank"
            >
              <img
                className="w-9"
                src={link3}
                alt="link3"
                width={24}
                height={24}
              />
            </a>
          </li>

          <li>
            {/* img from assets/social/link-1.svg */}
            {/* telegram */}
            <a href="https://t.me/eloopone" target="_blank">
              <img
                className="w-9 cursor-pointer"
                src={link1}
                alt="link1"
                width={24}
                height={24}
              />
            </a>
          </li>
        </ul>
        <div className="w-full my-8 border-b" />
        <div className="flex md:px-[90px]  mx-auto max-w-[1560px] justify-between w-full">
          <p className="whitespace-pre-line md:whitespace-normal">{`© 2023 Caroo Mobility GmbH\nall rights reserved`}</p>
          <div className="flex">
            <a href="https://eloop.app/en/imprint/" target="_blank">
              <Button variant={"link"} className="text-white">
                Imprint
              </Button>
            </a>
            <a href="https://eloop.app/en/privacy/" target="_blank">
              <Button variant={"link"} className="text-white">
                Privacy Policy
              </Button>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
