import { z } from "zod";

export const postBaseSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  thumbnail: z.string(),
  tag: z.string().nullable(),
  date: z.string(),
});

export const postSchema = postBaseSchema.extend({
  meta: z.object({
    title: z.string().optional(),
    description: z.string().optional(),
    keywords: z.array(z.string()),
    openGraph: z.object({
      description: z.string().optional(),
      title: z.string().optional(),
      images: z.array(z.string()).optional(),
      tags: z.array(z.string()),
      type: z.string().optional(),
    }),
  }),
});

export type Post = z.infer<typeof postSchema>;

//meta:{
//     title: string | null | undefined;
//     description: string | null | undefined;
//     keywords: string[];
//     openGraph: {
//         description: string | undefined;
//         title: string | undefined;
//         images: string[] | undefined;
//         tags: string[];
//         type: string;
//     };
// }
export const blogSchema = postSchema.extend({
  body: z.any(),
});
