import logo from "@/assets/logo.svg";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { Menu, X } from "lucide-react";
const navItems = [
  {
    name: "About us",
    link: "./#about-us",
  },
  {
    name: "Technologies",
    link: "./#tech",
  },
  {
    name: "How it works",
    link: "./#how-it-works",
  },
  {
    name: "ELOOP Coin",
    link: "./#eloop-coin",
  },
  {
    name: "Our vision",
    link: "./#vision",
  },
  // {
  //   name: "Blog",
  //   link: "/blog",
  // },
  {
    name: "Get in touch",
    link: "./#contact",
  },
  {
    name: "FAQ",
    link: "./#faq",
  },
];

export default function Nav() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="flex px-10 md:px-0 items-center justify-between mb-8">
      <a href={"/"}>
        <img src={logo} alt="ELOOP Network" />
      </a>

      {/* mobile nav */}
      {isOpen && (
        <div className="absolute h-screen scroll-none inset-0 flex flex-col items-center transition-all fade-in-10 duration-150 justify-center px-10 overflow-hidden bg-[#F4F5F9]">
          <div className="absolute top-9 right-14 " onClick={toggleNav}>
            <X size={32} />
          </div>
          <ul className="flex flex-col  gap-10 mb-24  text-center">
            {navItems.map((navItem, index) => (
              <a
                href={navItem.link}
                onClick={toggleNav}
                className="text-4xl font-bold cursor-pointer hover:text-gray-600"
                key={index}
              >
                {navItem.name}
              </a>
            ))}
          </ul>
        </div>
      )}

      <div onClick={toggleNav} className="md:hidden">
        <Menu size={32} />
      </div>

      {/* desktop nav */}
      <div className="items-center hidden ml-24 justify-center  gap-12 md:flex">
        <ul className="flex gap-8">
          {navItems.map((navItem, index) => (
            <a
              href={navItem.link}
              className="cursor-pointer text-sm hover:text-gray-600"
              key={index}
            >
              {navItem.name}
            </a>
          ))}
        </ul>
      </div>
      <div className="w-40 hidden md:flex items-end  justify-end"></div>
    </nav>
  );
}
