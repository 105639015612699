export { onRenderClient };

import ReactDOM from "react-dom/client";
import { PageShell } from "./PageShell";
import type { OnRenderClientAsync } from "vike/types";

let root: ReactDOM.Root;
const onRenderClient: OnRenderClientAsync = async (
  pageContext
): ReturnType<OnRenderClientAsync> => {
  const { Page } = pageContext;
  const page = (
    <PageShell pageContext={pageContext}>
      <Page />
    </PageShell>
  );
  const container = document.getElementById("react-root")!;
  if (pageContext.isHydration) {
    root = ReactDOM.hydrateRoot(container, page);
  } else {
    if (!root) {
      root = ReactDOM.createRoot(container);
    }
    root.render(page);
  }
};
