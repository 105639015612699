import { postBaseSchema, postSchema } from "@/renderer/schema";
import client from "@/tina/__generated__/client";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getBlogIndexData = async (
  postListResponse: Awaited<ReturnType<typeof client.queries.postConnection>>
) => {
  const posts = postListResponse.data.postConnection.edges
    ?.filter((p) => !p?.node?.draft)
    .map((p) => {
      const meta = {
        title: p?.node?.meta_title,
        description: p?.node?.meta_description,
        keywords: [],
        openGraph: {
          description: p?.node?.meta_description ?? undefined,
          title: p?.node?.meta_title ?? undefined,
          images: p?.node?.meta_image ? [p?.node?.meta_image] : undefined,
          tags: [],
          type: "article",
        },
      };
      return postSchema.parse({
        id: p?.node?._sys.filename,
        title: p?.node?.title,
        description: p?.node?.description,
        thumbnail: p?.node?.thumbnail,
        tag: p?.node?.tag,
        date: p?.node?.date,
        meta,
      });
    });
  const tags = new Set<string>();
  posts?.forEach((post) => {
    post.tag && tags.add(post.tag);
  });

  return {
    posts: posts ?? [],
    tags: Array.from(tags),
  };
};

export const getPopularBlog = async () => {
  const popularPosts = await client.queries.pop_posts({
    relativePath: "popular_posts.md",
  });
  const { post_1, post_2, post_3 } = popularPosts.data.pop_posts;
  return {
    popularPosts: [
      postBaseSchema.parse({
        id: post_1?._sys.filename,
        title: post_1?.title,
        description: post_1?.description,
        thumbnail: post_1?.thumbnail,
        tag: post_1?.tag,
        date: post_1?.date,
      }),
      postBaseSchema.parse({
        id: post_2?._sys.filename,
        title: post_2?.title,
        description: post_2?.description,
        thumbnail: post_2?.thumbnail,
        tag: post_2?.tag,
        date: post_2?.date,
      }),
      postBaseSchema.parse({
        id: post_3?._sys.filename,
        title: post_3?.title,
        description: post_3?.description,
        thumbnail: post_3?.thumbnail,
        tag: post_3?.tag,
        date: post_3?.date,
      }),
    ],
  };
};

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}
